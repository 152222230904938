var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "app-register", attrs: { fluid: "", "pa-0": "" } },
    [
      _c("inn-breadcrumbs", { attrs: { items: _setup.breadcrumbs } }),
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c("header", { staticClass: "app-welcome-message" }, [
            _vm.registerId
              ? _c("h1", [_vm._v("Meter: " + _vm._s(_setup.breadCrumbName))])
              : _vm.addModus
              ? _c("h1", [_vm._v("Meter toevoegen")])
              : _c("h1", [_vm._v("Meter")]),
          ]),
          _c("router-view", {
            on: { "update-register-reference": _setup.updateBreadCrumbName },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }